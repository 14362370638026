<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- Search -->
      <div class="col-sm-6 col-md-4 col-xl-3">
        <label class="d-inline-flex align-items-center">
          Search:
          <b-form-input
            v-model="filter"
            @input="filtersearch(allData)"
            type="search"
            placeholder="Search Currency"
            class="form-control form-control-sm ms-2"
          >
          </b-form-input>
        </label>
      </div>
      <!-- End search -->

      <div
        class="m-1"
        style="padding: 0"
        v-for="(data, index) in showData"
        :key="index"
      >
        <div
          v-b-toggle.data
          v-b-toggle="'accordion-' + index"
          class="accordion_title"
          @click="currency = data.symbol"
        >
          <div class="text-box">
            <div class="title">
              <p class="mb-0">{{ data.symbol }}</p>
            </div>

            <!-- Withdraw Section -->
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="data.withdraw_enable"
                @change="check(data, 'withdraw_enable')"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Withdraw Enable</label
              >
            </div>

            <!-- Deposit Section -->
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="data.deposit_enable"
                @change="check(data, 'deposit_enable')"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Deposit Enable</label
              >
            </div>

            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                style="fill: #000"
              >
                <path
                  d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <b-collapse :id="'accordion-' + index" accordion="my-accordion">
          <b-card
            v-for="(data1, index1) in data.currency_network"
            :key="index1"
          >
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      v-for="(title, index2) in headings"
                      :key="index2"
                    >
                      {{ title }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ data1.token_type }}</td>
                    <td>{{ data1.type }}</td>
                    <td>{{ data1.withdraw_min }}</td>
                    <td>{{ data1.withdraw_max }}</td>
                    <td>{{ data1.withdraw_commission }}</td>
                    <td>
                      <div
                        id="badges"
                        :style="
                          data1.withdraw_enable == 1
                            ? 'background-color: green'
                            : 'background-color: red'
                        "
                      >
                        {{ data1.withdraw_enable == 1 ? "Active" : "Inactive" }}
                      </div>
                    </td>

                    <td>
                      <div
                        id="badges"
                        :style="
                          data1.deposit_enable == 1
                            ? 'background-color: green'
                            : 'background-color: red'
                        "
                      >
                        {{ data1.deposit_enable == 1 ? "Active" : "Inactive" }}
                      </div>
                    </td>
                    <td>{{ data1.networks.network_name }}</td>
                    <td>{{ data1.networks.currency_symbol }}</td>
                    <td>
                      <!-- action section -->
                      <b-link
                        v-b-modal.modal-lg
                        color="primary"
                        @click="setData(data1)"
                        >Edit</b-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
        </b-collapse>
      </div>

      <div class="col-sm-6 col-md-4 col-xl-3">
        <div class="my-4 text-center">
          <b-modal
            id="modal-lg"
            size="lg"
            title="Edit Currency"
            title-class="font-18"
            hide-footer
          >
            <form @submit.prevent="save">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 mx-auto">
                  <div class="add_coin_form_box mb-3">
                    <div class="row middle-row align-items-center">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.form.deposit_enable.$error,
                          }"
                        >
                          <label for="selectdeposit_enable" class="form-label"
                            >Deposit</label
                          >
                          <div class="input-group mb-3">
                            <multiselect
                              v-model.trim="$v.form.deposit_enable.$model"
                              track-by="key"
                              label="value"
                              :options="status_options"
                            ></multiselect>
                          </div>
                          <div
                            class="error"
                            v-if="submitted && !$v.form.deposit_enable.required"
                          >
                            Deposit is required
                          </div>
                        </div>
                        <!--form-group-->
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.form.deposit_desc.$error,
                          }"
                        >
                          <label for="selectbuy" class="form-label"
                            >Deposit Description
                          </label>

                          <div class="input-group mb-3">
                            <textarea
                              class="form-control"
                              row="3"
                              cols="50"
                              id="buymin"
                              placeholder="Deposit Description"
                              v-model="$v.form.deposit_desc.$model"
                              aria-describedby="buymin"
                            >
                            </textarea>
                          </div>

                          <div
                            class="error"
                            v-if="submitted && !$v.form.deposit_desc.required"
                          >
                            Deposit Description is required
                          </div>
                        </div>
                        <!--form-group-->
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.form.withdraw_enable.$error,
                          }"
                        >
                          <label for="selectbuy" class="form-label"
                            >Withdraw
                          </label>

                          <div class="input-group mb-3">
                            <multiselect
                              v-model.trim="$v.form.withdraw_enable.$model"
                              track-by="key"
                              label="value"
                              :options="status_options1"
                            ></multiselect>
                          </div>

                          <div
                            class="error"
                            v-if="
                              submitted && !$v.form.withdraw_enable.required
                            "
                          >
                            Withdraw is required
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.form.withdraw_desc.$error,
                          }"
                        >
                          <label for="selectbuy" class="form-label"
                            >Withdraw Description
                          </label>

                          <div class="input-group mb-3">
                            <textarea
                              class="form-control"
                              row="3"
                              cols="50"
                              id="withdraw_desc"
                              placeholder="Withdraw Description"
                              v-model="$v.form.withdraw_desc.$model"
                              aria-describedby="withdraw_desc"
                            >
                            </textarea>
                          </div>

                          <div
                            class="error"
                            v-if="submitted && !$v.form.withdraw_desc.required"
                          >
                            Withdraw description is required
                          </div>
                        </div>
                        <!--form-group-->
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.form.withdraw_min.$error,
                          }"
                        >
                          <label for="selectbuy" class="form-label"
                            >Withdraw Min
                          </label>

                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control"
                              id="withdraw_min"
                              placeholder="Min"
                              @keypress="keyChange($event, decimalLength)"
                              onpaste="return false;"
                              v-model="$v.form.withdraw_min.$model"
                              aria-describedby="withdraw_min"
                            />
                          </div>

                          <div
                            class="error"
                            v-if="submitted && !$v.form.withdraw_min.required"
                          >
                            withdraw_min is required
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.form.withdraw_max.$error,
                          }"
                        >
                          <label for="selectbuy" class="form-label"
                            >Withdraw Max
                          </label>

                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control"
                              id="withdraw_max"
                              placeholder="Max"
                              @keypress="keyChange($event, decimalLength)"
                              onpaste="return false;"
                              v-model="$v.form.withdraw_max.$model"
                              aria-describedby="withdraw_max"
                            />
                          </div>

                          <div
                            class="error"
                            v-if="submitted && !$v.form.withdraw_max.required"
                          >
                            withdraw_max is required
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error':
                              $v.form.withdraw_commission.$error,
                          }"
                        >
                          <label for="selectbuy" class="form-label"
                            >Withdraw commission</label
                          >

                          <div class="input-group mb-3">
                            <select
                              class="form-select shadow-none"
                              v-model="form.type"
                            >
                              <option value="percentage">Percentage</option>
                              <option value="flat">Flat</option>
                            </select>
                            <input
                              type="text"
                              class="form-control"
                              id="buycomm"
                              placeholder="Commission"
                              @keypress="keyChange($event, 2)"
                              onpaste="return false;"
                              v-model="$v.form.withdraw_commission.$model"
                              aria-describedby="buycomm"
                            />
                            <span
                              class="input-group-text"
                              id="buycomm"
                              v-if="form.type == 'percentage'"
                              >%</span
                            >
                            <span
                              class="input-group-text"
                              id="buycomm"
                              v-else
                              >{{ currency }}</span
                            >
                          </div>

                          <div
                            class="error"
                            v-if="
                              submitted && !$v.form.withdraw_commission.required
                            "
                          >
                            Withdraw Commission is required
                          </div>
                          <div
                            class="error"
                            v-if="
                              form.withdraw_commission > 99 &&
                              form.type == 'percentage'
                                ? (customError = true)
                                : (customError = false)
                            "
                          >
                            Maximum percentage is 99.
                          </div>
                          <div
                            class="error"
                            v-if="
                              form.withdraw_min != '' &&
                              parseFloat(form.withdraw_commission) >
                                parseFloat(form.withdraw_min) &&
                              form.type == 'flat'
                                ? (customError = true)
                                : (customError = false)
                            "
                          >
                            Withdraw value should be less than Min limit.
                          </div>
                        </div>
                        <!--form-group-->
                      </div>

                      <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                        <div
                          class="form-group"
                          :class="{
                            'form-group--error': $v.form.deposit_min.$error,
                          }"
                        >
                          <label for="selectbuy" class="form-label"
                            >Deposit Min Limit</label
                          >

                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control"
                              id="deposimin"
                              placeholder="Deposit Minimum"
                              onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                              onpaste="return false;"
                              v-model="$v.form.deposit_min.$model"
                              aria-describedby="deposimin"
                            />

                            <span class="input-group-text" id="deposimin">{{
                              currency
                            }}</span>
                          </div>

                          <div
                            class="error"
                            v-if="submitted && !$v.form.deposit_min.required"
                          >
                            Deposit Minimum is required.
                          </div>
                        </div>
                        <!--form-group-->
                      </div>
                    </div>
                  </div>
                  <!--row form-row-->
                  <div class="col-12 mt-5">
                    <div class="form-group btn-submit">
                      <div class="spinner-border" role="status" v-if="loading">
                        <span class="visually-hidden">Loading...</span>
                      </div>

                      <button
                        type="submit"
                        class="btn btn-primary"
                        id="submit"
                        v-if="!loading"
                      >
                        Submit
                      </button>
                    </div>
                    <!---submit button-->
                  </div>
                </div>
                <!--col-xl-8 col-lg-8 col-md-8-->
              </div>
              <!--row-->
            </form>
          </b-modal>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import ApiClass from "../../api/api";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Currencies",
      allData: [],
      showData: [],
      headings: [
        "Token Type",
        "Commission Type",
        "Withdraw Min",
        "Withdraw Max",
        "Withdraw Commission",
        "Withdraw Enable",
        "Deposit Enable",
        "Network",
        "Currency Symbol",
        "Action",
      ],
      items: [
        {
          text: "Dashboards",
          href: "#",
        },
        {
          text: "Currencies",
          active: true,
        },
      ],
      submitted: false,
      loading: false,
      filter: "",
      form: {
        target_id: "",
        deposit_enable: "",
        deposit_desc: "",
        withdraw_enable: "",
        withdraw_desc: "",
        withdraw_min: "",
        withdraw_max: "",
        type: "",
        withdraw_commission: "",
        deposit_min: "",
      },
      status_options: [
        { key: 1, value: "Active" },
        { key: 0, value: "InActive" },
      ],
      status_options1: [
        { key: 1, value: "Active" },
        { key: 0, value: "InActive" },
      ],
      decimalLength: "8",
      currency: "",
    };
  },
  validations: {
    form: {
      // target_id: { required },
      deposit_enable: { required },
      deposit_desc: { required },
      withdraw_enable: { required },
      withdraw_desc: { required },
      withdraw_min: { required },
      withdraw_max: { required },
      type: { required },
      withdraw_commission: { required },
      deposit_min: { required },
    },
  },

  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await ApiClass.getRequest("admin/w_2_w/get", true).then((res) => {
        if (res.data.status_code == 1) {
          this.allData = this.showData = res.data.data;
        }
      });
    },
    async save() {
      this.submitted = true;

      if (this.$v.$invalid) {
        return;
      }
      this.submitted = false;
      this.loading = true;
      let obj = {
        target_id: this.form.target_id,
        deposit_enable: this.form.deposit_enable.key,
        deposit_desc: this.form.deposit_desc,
        withdraw_enable: this.form.withdraw_enable.key,
        withdraw_desc: this.form.withdraw_desc,
        withdraw_min: this.form.withdraw_min,
        withdraw_max: this.form.withdraw_max,
        type: this.form.type,
        withdraw_commission: this.form.withdraw_commission,
        deposit_min: this.form.deposit_min,
      };
      var result = await ApiClass.updateRequest(
        "admin/w_2_w/update",
        true,
        obj
      );

      if (result.data.status_code == 1) {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
        this.$bvModal.hide("modal-lg");
        this.getData();
        this.setData();
      } else {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
      }
    },
    setData(data = null) {
      this.form.target_id = data.id;
      this.form.deposit_enable =
        data.deposit_enable == "1"
          ? { key: 1, value: "Active" }
          : { key: 0, value: "InActive" };
      this.form.deposit_desc = data.deposit_desc;
      this.form.withdraw_enable =
        data.withdraw_enable == "1"
          ? { key: 1, value: "Active" }
          : { key: 0, value: "InActive" };
      this.form.withdraw_desc = data.withdraw_desc;
      this.form.withdraw_min = data.withdraw_min;
      this.form.withdraw_max = data.withdraw_max;
      this.form.type = data.type;
      this.form.withdraw_commission = data.withdraw_commission;
      this.form.deposit_min = data.deposit_min;
    },
    keyChange(event, decimal = null) {
      if (decimal == 0 && event.charCode == 46) {
        return event.preventDefault();
      }

      let res =
        event.charCode != 8 &&
        ((event.charCode >= 48 && event.charCode <= 57) ||
          (event.charCode == 46 && event.target.value.indexOf(".") == -1));

      if (decimal != null && res && event.target.value.indexOf(".") != -1) {
        res = event.target.value.split(".")[1].length < decimal;
      }

      return res ? res : event.preventDefault();
    },
    filtersearch(filterData) {
      return (this.showData = filterData.filter((item) => {
        return (
          item.symbol.toUpperCase().indexOf(this.filter.toUpperCase()) > -1
        );
      }));
    },
    async check(e, colm) {
      var result = await ApiClass.updateRequest(
        "admin/currency/update_status/" + e.id + "/" + e[colm] + "/" + colm,
        true
      );

      if (result.data.status_code == 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
      }
    },
  },
};
</script>
<style scoped>
.active {
  color: green;
}
.inactive {
  color: red;
}
body {
  color: #6a6c6f;
  background-color: #f1f3f6;
  margin-top: 30px;
}

.container {
  max-width: 960px;
}

/* */

.panel-default > .panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.panel-default > .panel-heading a {
  display: block;
  padding: 10px 15px;
}

.panel-default > .panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: transform 0.25s linear;
  -webkit-transition: -webkit-transform 0.25s linear;
}

.panel-default > .panel-heading a[aria-expanded="true"] {
  background-color: #eee;
}

.panel-default > .panel-heading a[aria-expanded="true"]:after {
  content: "\2212";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-default > .panel-heading a[aria-expanded="false"]:after {
  content: "\002b";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.accordion_title {
  background-color: #fff;
  padding: 10px 10px;
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-body {
  padding: 0;
  border-top: 1px solid #ccc;
}

.title p {
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

div#badges {
  max-width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
}

.title {
  min-width: 70px;
}
</style>
